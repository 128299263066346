<template>
    <div class="car-aos">
        <b-img
            src="./images/how-it-works-drivetrain.svg"
            alt="Drivetrain"
            class="drivetrain"
            data-aos="fade-right"
            data-aos-delay="0"
            :data-aos-anchor="'#' + anchorPrefix + '-drivetrain-anchor'"
        />
        <b-img
            src="./images/how-it-works-engine.svg"
            alt="Engine"
            class="engine"
            data-aos="fade-right"
            data-aos-delay="100"
            :data-aos-anchor="'#' + anchorPrefix + '-engine-anchor'"
        />
        <b-img
            src="./images/how-it-works-transmission.svg"
            alt="Transmission"
            class="transmission"
            data-aos="fade-right"
            data-aos-delay="200"
            :data-aos-anchor="'#' + anchorPrefix + '-transmission-anchor'"
        />
        <b-img src="./images/how-it-works-car.svg" alt="Vehicle" class="car" />
    </div>
</template>

<style lang="scss">
.car-aos {
    display: table;
    position: relative;
    margin: 0 auto;

    .drivetrain {
        position: absolute;
        top: 12px;
        left: 36px;
        height: 107px;
    }

    .engine {
        position: absolute;
        left: 13px;
        top: 45px;
        height: 41px;
    }

    .transmission {
        position: absolute;
        left: 49px;
        top: 53px;
        height: 25px;
    }

    .car {
        height: 130px;
    }

    @include media-breakpoint-up(sm) {
        .drivetrain {
            position: absolute;
            top: 21px;
            left: 66px;
            height: 190px;
        }

        .engine {
            position: absolute;
            left: 21px;
            top: 79px;
            height: 75px;
        }

        .transmission {
            position: absolute;
            left: 89px;
            top: 96px;
            height: 42px;
        }

        .car {
            height: 232px;
        }
    }
}
</style>

<script>
import AOS from "aos";
import "aos/src/sass/aos.scss";

export default {
    props: {
        anchorPrefix: {
            type: String,
            required: true
        }
    },

    created() {
        AOS.init({
            duration: 600,
            disable: false
        });
    }
};
</script>
