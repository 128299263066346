var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sticky-footer" }, [
    _c(
      "div",
      {
        staticClass: "position-fixed d-lg-none d-md-block w-100",
        attrs: { id: "floatingFooter", "data-aos": "fade-up" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center w-100 footer-block"
          },
          _vm._l(_vm.links, function(link) {
            return _c(
              "a",
              {
                key: link.name,
                staticClass: "btn btn-orange btn-xlg mx-3 px-5",
                attrs: { href: link.url }
              },
              [
                _vm._v(
                  "\n                " + _vm._s(link.name) + "\n            "
                )
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }