import Vue from "vue";
import _ from "lodash";
import "@/lib/polyfills";
import "../../sass/vendor.scss";
import "../../sass/core.scss";
import "../../sass/howItWorks.scss";

import VueI18n from "vue-i18n";
import Vuex from "vuex";
import pathify from "@/store/pathify";
import user from "@/store/modules/user";
import headerTransitionStore from "@/store/modules/headerTransitionStore";
import translated from "@/store/modules/translated";
import campaign from "@/store/modules/campaign";
import location from "@/store/modules/location";
import sessionReset from "@/store/modules/sessionReset";
import CarSaverPlugin from "@/lib/CarSaverPlugin";
import StickyFooter from "Components/StickyFooter";
import CarAos from "Modules/HowItWorks/CarAos";
import WarrantyPoints from "Modules/HowItWorks/WarrantyPoints";
import LifetimeWarrantyDisclaimer from "Components/LifetimeWarrantyDisclaimer";

const environment = _.get(window, "appConfig.env[0]", "local") || "local";

Vue.use(Vuex);
Vue.use(VueI18n);

const debug = environment !== "production";

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        user,
        translated,
        sessionReset,
        campaign,
        headerTransitionStore,
        location
    },
    strict: debug
});

const defaultMessages = _.get(store, "state.translated.text", {});
const messages = {
    default: defaultMessages,
    en: {},
    es: {}
};
const i18n = new VueI18n({
    locale: "default",
    fallbackLocale: "default",
    messages
});

Vue.use(CarSaverPlugin);

if (document.getElementById("root") != null) {
    // eslint-disable-next-line no-new
    new Vue({
        el: "#root",

        store,

        i18n,

        components: {
            StickyFooter,
            CarAos,
            WarrantyPoints,
            LifetimeWarrantyDisclaimer
        }
    });
}
