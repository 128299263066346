<template>
    <div class="warranty-point-wrapper">
        <div class="warranty-points text-center">
            <div
                :id="anchorPrefix + '-engine-anchor'"
                class="warranty-point"
                data-aos="fade-right"
                data-aos-delay="0"
            >
                <img src="/assets/images/car_shield.jpg" alt="Engine" />
                Engine
            </div>
            <div
                :id="anchorPrefix + '-drivetrain-anchor'"
                class="warranty-point"
                data-aos="fade-right"
                data-aos-delay="100"
            >
                <img src="/assets/images/car_shield.jpg" alt="Drivetrain" />
                Drivetrain
            </div>
            <div
                :id="anchorPrefix + '-transmission-anchor'"
                class="warranty-point"
                data-aos="fade-right"
                data-aos-delay="200"
            >
                <img src="/assets/images/car_shield.jpg" alt="Transmission" />
                Transmission
            </div>
        </div>
    </div>
</template>

<script>
import AOS from "aos";
import "aos/src/sass/aos.scss";

export default {
    props: {
        anchorPrefix: {
            type: String,
            required: true
        }
    },

    created() {
        AOS.init({
            duration: 600
        });
    }
};
</script>
