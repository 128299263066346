var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "car-aos" },
    [
      _c("b-img", {
        staticClass: "drivetrain",
        attrs: {
          src: require("./images/how-it-works-drivetrain.svg"),
          alt: "Drivetrain",
          "data-aos": "fade-right",
          "data-aos-delay": "0",
          "data-aos-anchor": "#" + _vm.anchorPrefix + "-drivetrain-anchor"
        }
      }),
      _vm._v(" "),
      _c("b-img", {
        staticClass: "engine",
        attrs: {
          src: require("./images/how-it-works-engine.svg"),
          alt: "Engine",
          "data-aos": "fade-right",
          "data-aos-delay": "100",
          "data-aos-anchor": "#" + _vm.anchorPrefix + "-engine-anchor"
        }
      }),
      _vm._v(" "),
      _c("b-img", {
        staticClass: "transmission",
        attrs: {
          src: require("./images/how-it-works-transmission.svg"),
          alt: "Transmission",
          "data-aos": "fade-right",
          "data-aos-delay": "200",
          "data-aos-anchor": "#" + _vm.anchorPrefix + "-transmission-anchor"
        }
      }),
      _vm._v(" "),
      _c("b-img", {
        staticClass: "car",
        attrs: { src: require("./images/how-it-works-car.svg"), alt: "Vehicle" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }