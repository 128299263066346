<template>
    <div class="sticky-footer">
        <div
            id="floatingFooter"
            data-aos="fade-up"
            class="position-fixed d-lg-none d-md-block w-100"
        >
            <div
                class="d-flex justify-content-center align-items-center w-100 footer-block"
            >
                <a
                    v-for="link in links"
                    :key="link.name"
                    :href="link.url"
                    class="btn btn-orange btn-xlg mx-3 px-5"
                >
                    {{ link.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from "aos";
import { get } from "vuex-pathify";

export default {
    name: "StickyFooter",

    props: {
        links: {
            type: Array, // array of objects [{ text: 'text', url: '/some/url' }, {}, ...]
            required: true
        }
    },

    computed: {
        lang: get("translated/lang")
    },

    mounted() {
        AOS.init();
    }
};
</script>
<style lang="scss" scoped>
.sticky-footer {
    #floatingFooter {
        z-index: 70;
        bottom: 0;
        background: #fff;
        height: auto;
        font-size: px2rem(16);

        @include media-breakpoint-down(sm) {
            .btn-primary,
            .btn-dark-blue {
                width: 100% !important;
            }
        }

        .footer-block {
            flex-flow: row nowrap;
            height: 70px;
            background-color: #f9f9f9;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        }
    }
}
</style>
