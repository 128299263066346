var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "warranty-point-wrapper" }, [
    _c("div", { staticClass: "warranty-points text-center" }, [
      _c(
        "div",
        {
          staticClass: "warranty-point",
          attrs: {
            id: _vm.anchorPrefix + "-engine-anchor",
            "data-aos": "fade-right",
            "data-aos-delay": "0"
          }
        },
        [
          _c("img", {
            attrs: { src: "/assets/images/car_shield.jpg", alt: "Engine" }
          }),
          _vm._v("\n            Engine\n        ")
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "warranty-point",
          attrs: {
            id: _vm.anchorPrefix + "-drivetrain-anchor",
            "data-aos": "fade-right",
            "data-aos-delay": "100"
          }
        },
        [
          _c("img", {
            attrs: { src: "/assets/images/car_shield.jpg", alt: "Drivetrain" }
          }),
          _vm._v("\n            Drivetrain\n        ")
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "warranty-point",
          attrs: {
            id: _vm.anchorPrefix + "-transmission-anchor",
            "data-aos": "fade-right",
            "data-aos-delay": "200"
          }
        },
        [
          _c("img", {
            attrs: { src: "/assets/images/car_shield.jpg", alt: "Transmission" }
          }),
          _vm._v("\n            Transmission\n        ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }